import React, { ReactElement } from "react";

export default function MedicalSoftwareLogo(): ReactElement {
    return (
        <svg
            width="171"
            height="40"
            viewBox="0 0 171 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M52.9208 32.893L51.63 32.3338C50.9211 32.0343 50.1316 31.7076 50.1316 30.8365C50.1316 30.0255 50.8042 29.5139 51.8194 29.5139C52.6565 29.5139 53.3201 29.8395 53.8748 30.3624L54.4862 29.6228C53.8498 28.966 52.8936 28.501 51.8206 28.501C50.1747 28.501 48.9701 29.5207 48.9701 30.9046C48.9701 32.2362 49.9603 32.876 50.8042 33.2355L52.1053 33.8095C52.9594 34.1827 53.615 34.4787 53.615 35.4021C53.615 36.2823 52.9106 36.8744 51.723 36.8744C50.7816 36.8744 49.8764 36.4286 49.2253 35.7526L48.5356 36.5443C49.3274 37.3689 50.4322 37.8884 51.7026 37.8884C53.606 37.8884 54.7879 36.7451 54.7879 35.3147C54.7856 33.9672 53.9678 33.3433 52.9208 32.893Z"
                fill="#8989C2"
            />
            <path
                d="M59.0572 30.8486C57.4136 30.8486 55.9492 32.1383 55.9492 34.3763C55.9492 36.5961 57.4136 37.887 59.0572 37.887C60.7019 37.887 62.1652 36.5973 62.1652 34.3763C62.164 32.1395 60.7008 30.8486 59.0572 30.8486ZM59.0572 36.9409C57.9036 36.9409 57.1221 35.9121 57.1221 34.3763C57.1221 32.8405 57.9036 31.7946 59.0572 31.7946C60.2108 31.7946 60.9923 32.8405 60.9923 34.3763C60.9923 35.9121 60.2108 36.9409 59.0572 36.9409Z"
                fill="#8989C2"
            />
            <path
                d="M69.7938 36.9617C69.0213 36.9617 68.757 36.4864 68.757 35.6675V31.939H70.5741V31.0157H68.757V29.135H67.8099L67.6738 31.0157H65.218V29.9608C65.218 29.0953 65.5288 28.653 66.1629 28.653C66.4011 28.653 66.6665 28.7074 66.9228 28.8254L67.1622 27.9599C66.8593 27.8317 66.4567 27.7285 66.0483 27.7285C64.7042 27.7285 64.0837 28.594 64.0837 29.9574V31.0203L63.1729 31.0826V31.9413H64.0837V37.724H65.218V31.9413H67.6227V35.6493C67.6227 36.9844 68.1059 37.8884 69.534 37.8884C69.9526 37.8884 70.4029 37.7682 70.7783 37.64L70.5594 36.7836C70.3405 36.8732 70.032 36.9617 69.7938 36.9617Z"
                fill="#8989C2"
            />
            <path
                d="M78.4757 34.8881C78.3305 35.5256 78.2057 36.129 78.0605 36.7586H78.0061C77.8507 36.129 77.7055 35.5256 77.5399 34.8881L76.4941 31.0156H75.387L74.3514 34.8881C74.1858 35.5154 74.0406 36.129 73.8954 36.7586H73.8398C73.7127 36.129 73.5868 35.5256 73.4417 34.8881L72.4469 31.0156H71.2876L73.1524 37.7216H74.4784L75.4187 34.1349C75.5991 33.5009 75.7341 32.8668 75.8792 32.1862H75.9348C76.097 32.8668 76.2252 33.4839 76.3886 34.1179L77.3561 37.7216H78.7377L80.5367 31.0156H79.4557L78.4757 34.8881Z"
                fill="#8989C2"
            />
            <path
                d="M84.3588 30.8486C83.2823 30.8486 82.342 31.3194 81.725 31.7243L82.1696 32.5024C82.6948 32.1508 83.3935 31.7833 84.1694 31.7833C85.2651 31.7833 85.551 32.6147 85.5532 33.4768C82.6959 33.7876 81.4346 34.5181 81.4346 35.9791C81.4346 37.1894 82.2773 37.8858 83.3992 37.8858C84.2317 37.8858 84.9781 37.4503 85.6179 36.9183H85.6553L85.7506 37.7202H86.6875V33.6061C86.6875 31.9364 85.9956 30.8486 84.3588 30.8486ZM85.5532 36.0846C84.9044 36.6574 84.369 36.9727 83.7213 36.9727C83.0623 36.9727 82.5462 36.663 82.5462 35.9008C82.5462 35.0433 83.3062 34.4931 85.5532 34.2232V36.0846Z"
                fill="#8989C2"
            />
            <path
                d="M89.8719 32.2365H89.8345L89.7392 31.016H88.8022V37.722H89.9365V33.4162C90.3903 32.2808 91.0663 31.8736 91.6335 31.8736C91.9023 31.8736 92.0532 31.911 92.2846 31.9847L92.4967 30.9888C92.2857 30.889 92.0781 30.8516 91.7809 30.8516C91.0243 30.8493 90.3381 31.3858 89.8719 32.2365Z"
                fill="#8989C2"
            />
            <path
                d="M95.9651 30.8486C94.4214 30.8486 92.9502 32.1939 92.9502 34.3763C92.9502 36.5791 94.3737 37.887 96.1614 37.887C97.0734 37.887 97.7868 37.5762 98.3755 37.1916L97.9695 36.4464C97.4726 36.7753 96.944 36.9829 96.3009 36.9829C95.0225 36.9829 94.1537 36.0619 94.0731 34.6406H98.5888C98.6205 34.475 98.6421 34.2356 98.6421 33.9963C98.641 32.0771 97.6745 30.8486 95.9651 30.8486ZM94.0607 33.8273C94.1991 32.5104 95.0418 31.7538 95.9833 31.7538C97.0325 31.7538 97.6371 32.4843 97.6371 33.8273H94.0607Z"
                fill="#8989C2"
            />
            <path
                d="M109.389 39.0317C108.411 39.0317 107.608 38.6142 107.195 37.8157C109.088 37.4754 110.366 35.7524 110.366 33.1617C110.366 30.2669 108.77 28.502 106.496 28.502C104.222 28.502 102.626 30.2624 102.626 33.1617C102.626 35.8125 103.967 37.548 105.932 37.8293C106.45 39.0759 107.593 40.0003 109.27 40.0003C109.811 40.0003 110.289 39.8983 110.562 39.7916L110.338 38.9012C110.1 38.9715 109.792 39.0317 109.389 39.0317ZM106.497 36.9321C104.88 36.9321 103.817 35.4677 103.817 33.1617C103.817 30.9055 104.88 29.5149 106.497 29.5149C108.114 29.5149 109.178 30.9055 109.178 33.1617C109.177 35.4677 108.113 36.9321 106.497 36.9321Z"
                fill="#8989C2"
            />
            <path
                d="M116.308 35.7708C115.674 36.5614 115.198 36.9017 114.513 36.9017C113.626 36.9017 113.249 36.3663 113.249 35.1174V31.0146H112.114V35.2637C112.114 36.9743 112.748 37.8862 114.162 37.8862C115.083 37.8862 115.751 37.403 116.374 36.6703H116.411L116.506 37.7206H117.443V31.0146H116.308V35.7708V35.7708Z"
                fill="#8989C2"
            />
            <path
                d="M122.314 30.8486C120.669 30.8486 119.206 32.1383 119.206 34.3763C119.206 36.5961 120.669 37.887 122.314 37.887C123.959 37.887 125.422 36.5973 125.422 34.3763C125.422 32.1395 123.959 30.8486 122.314 30.8486ZM122.314 36.9409C121.16 36.9409 120.379 35.9121 120.379 34.3763C120.379 32.8405 121.16 31.7946 122.314 31.7946C123.468 31.7946 124.249 32.8405 124.249 34.3763C124.249 35.9121 123.468 36.9409 122.314 36.9409Z"
                fill="#8989C2"
            />
            <path
                d="M129.315 36.9614C128.542 36.9614 128.278 36.4862 128.278 35.6672V31.9388H130.095V31.0154H128.278V29.1348H127.331L127.194 31.0154L126.146 31.0812V31.9399H127.143V35.6479C127.143 36.983 127.626 37.887 129.054 37.887C129.472 37.887 129.922 37.7668 130.297 37.6386L130.078 36.7822C129.861 36.8729 129.553 36.9614 129.315 36.9614Z"
                fill="#8989C2"
            />
            <path
                d="M133.93 30.8486C132.386 30.8486 130.915 32.1939 130.915 34.3763C130.915 36.5791 132.339 37.887 134.126 37.887C135.038 37.887 135.752 37.5762 136.34 37.1916L135.934 36.4464C135.437 36.7753 134.909 36.9829 134.266 36.9829C132.987 36.9829 132.119 36.0619 132.038 34.6406H136.554C136.587 34.475 136.607 34.2356 136.607 33.9963C136.606 32.0771 135.639 30.8486 133.93 30.8486ZM132.027 33.8273C132.165 32.5104 133.008 31.7538 133.949 31.7538C134.999 31.7538 135.603 32.4843 135.603 33.8273H132.027Z"
                fill="#8989C2"
            />
            <path
                d="M140.414 33.8589C139.655 33.5753 138.926 33.3326 138.926 32.6951C138.926 32.1835 139.316 31.7332 140.16 31.7332C140.753 31.7332 141.228 31.9884 141.69 32.3299L142.232 31.6028C141.713 31.191 140.982 30.8496 140.146 30.8496C138.693 30.8496 137.833 31.6811 137.833 32.753C137.833 33.885 138.894 34.3467 139.833 34.6903C140.576 34.966 141.368 35.2722 141.368 35.9619C141.368 36.5336 140.931 37.0032 140.05 37.0032C139.244 37.0032 138.653 36.6776 138.07 36.208L137.509 36.9555C138.139 37.483 139.058 37.8868 140.011 37.8868C141.593 37.8868 142.458 36.9805 142.458 35.8927C142.458 34.6166 141.381 34.2219 140.414 33.8589Z"
                fill="#8989C2"
            />
            <path
                d="M61.4236 15.522L51.2558 0.574219H49.7256V24.1279H52.8812V8.22393L60.9767 20.1125H61.8059L69.9013 8.22393V24.1279H73.0887V0.574219H71.5268L61.4236 15.522Z"
                fill="#4D469D"
            />
            <path
                d="M85.87 6.62988C83.3201 6.62988 81.2375 7.46926 79.6234 9.14802C78.0297 10.8483 77.2334 12.9729 77.2334 15.5228C77.2334 16.6707 77.4137 17.7653 77.7756 18.8066C78.1363 19.8479 78.6683 20.8041 79.3693 21.6752C80.0703 22.5464 81.0208 23.2372 82.2221 23.7465C83.4221 24.2569 84.7981 24.5121 86.3498 24.5121C87.7098 24.5121 89.0483 24.283 90.3663 23.827C91.6844 23.371 92.7892 22.8027 93.6807 22.1221L92.374 19.7957C91.588 20.3685 90.6522 20.8313 89.5689 21.1818C88.4845 21.5323 87.433 21.707 86.4133 21.707C84.6926 21.707 83.3325 21.2725 82.3332 20.4003C81.335 19.5291 80.6975 18.3393 80.4208 16.8306H94.0301C94.0936 16.0026 94.1254 15.4819 94.1254 15.2687C94.1254 12.6133 93.3495 10.5092 91.7989 8.9586C90.2676 7.40574 88.2917 6.62988 85.87 6.62988ZM80.4197 14.1832C80.6749 12.7608 81.301 11.597 82.3003 10.6929C83.2985 9.79004 84.4884 9.33859 85.87 9.33859C87.2935 9.33859 88.4777 9.77983 89.4237 10.6612C90.3686 11.5437 90.8847 12.7165 90.9698 14.1832H80.4197V14.1832Z"
                fill="#4D469D"
            />
            <path
                d="M111.208 9.53038C109.656 7.5964 107.659 6.62998 105.215 6.62998C102.75 6.62998 100.71 7.50112 99.0957 9.24341C97.4805 10.9437 96.6729 13.0467 96.6729 15.5535C96.6729 18.0819 97.4805 20.2177 99.0957 21.96C100.731 23.6808 102.792 24.5417 105.279 24.5417C107.744 24.5417 109.72 23.607 111.208 21.7366V24.1265H114.267V0H111.208V9.53038ZM109.806 19.9523C108.742 21.1422 107.34 21.7377 105.599 21.7377C103.855 21.7377 102.443 21.1433 101.36 19.9523C100.296 18.7624 99.7661 17.2969 99.7661 15.5546C99.7661 13.8543 100.297 12.4104 101.36 11.2194C102.443 10.0295 103.855 9.4351 105.599 9.4351C107.34 9.4351 108.742 10.0295 109.806 11.2194C110.889 12.3888 111.431 13.8328 111.431 15.5546C111.431 17.3185 110.889 18.784 109.806 19.9523Z"
                fill="#4D469D"
            />
            <path
                d="M122.044 8.57422H118.984V24.1277H122.044V8.57422Z"
                fill="#4D469D"
            />
            <path
                d="M134.922 9.435C135.984 9.435 136.977 9.71177 137.901 10.263C138.825 10.8154 139.576 11.5811 140.148 12.5577L142.667 11.0922C142.008 9.73332 140.972 8.64894 139.559 7.84131C138.145 7.03369 136.6 6.62988 134.922 6.62988C132.202 6.62988 129.991 7.46926 128.292 9.14802C126.591 10.8483 125.742 12.9944 125.742 15.5863C125.742 18.1793 126.581 20.3141 128.26 21.9928C129.937 23.6716 132.106 24.511 134.762 24.511C136.419 24.511 137.969 24.1072 139.416 23.2995C140.818 22.5135 141.88 21.4506 142.603 20.1122L140.053 18.6466C139.564 19.5393 138.826 20.2721 137.838 20.846C136.851 21.42 135.826 21.7059 134.763 21.7059C133.041 21.7059 131.628 21.1217 130.524 19.9534C129.398 18.7635 128.835 17.3082 128.835 15.5863C128.835 13.844 129.409 12.3785 130.557 11.1886C131.722 10.0192 133.179 9.435 134.922 9.435Z"
                fill="#4D469D"
            />
            <path
                d="M159.399 9.53028C157.806 7.5963 155.797 6.62988 153.374 6.62988C150.91 6.62988 148.869 7.46926 147.255 9.14802C145.64 10.7848 144.832 12.8878 144.832 15.4581C144.832 18.0296 145.64 20.1859 147.255 21.9282C148.869 23.6705 150.91 24.5416 153.374 24.5416C155.861 24.5416 157.869 23.5967 159.399 21.7047V24.1276H162.332V7.0439H159.399V9.53028ZM157.965 19.9522C156.881 21.1421 155.467 21.7376 153.726 21.7376C151.984 21.7376 150.582 21.1432 149.519 19.9522C148.455 18.7623 147.925 17.307 147.925 15.5852C147.925 13.8644 148.456 12.4091 149.519 11.2181C150.582 10.0282 151.984 9.43387 153.726 9.43387C155.467 9.43387 156.881 10.0282 157.965 11.2181C159.048 12.3876 159.59 13.8315 159.59 15.5534C159.59 17.3184 159.048 18.7839 157.965 19.9522Z"
                fill="#4D469D"
            />
            <path d="M170.139 0H167.08V24.1277H170.139V0Z" fill="#4D469D" />
            <path
                d="M122.044 2.9707H118.984V6.80577H122.044V2.9707Z"
                fill="#F37421"
            />
            <path
                d="M2.229 25.0921C2.23354 27.3471 2.29366 27.4106 4.53616 27.4106C7.35263 27.4106 10.1702 27.4106 12.9867 27.4106C15.7431 27.4106 18.4983 27.4106 21.2535 27.4106C23.9712 27.4106 26.689 27.4106 29.4068 27.4106C29.4114 27.4106 29.4159 27.4106 29.4204 27.4106C32.1359 27.4106 35.1498 27.4106 38.7659 27.4106C38.9871 27.4106 39.2106 27.3925 39.4295 27.4118C39.5452 27.422 39.6473 27.4197 39.7482 27.4175C39.8401 27.4129 39.9286 27.4061 40.008 27.3902C40.0182 27.388 40.0284 27.3868 40.0386 27.3846C40.7566 27.2269 40.9619 26.603 40.9619 25.8238C40.9619 25.7818 40.9619 25.7398 40.9619 25.699C40.9619 22.6012 40.9698 19.5148 40.9608 16.4238H2.229V25.0921Z"
                fill="#DFDDEF"
            />
            <path
                d="M24.0665 14.0185V8.70312H23.2317H21.1536H19.9002H19.2616V14.0185H13.9463V18.8222H19.2616V24.1375H24.0665V18.8222H29.3818V14.0185H24.0665Z"
                fill="#4D469D"
            />
            <path
                d="M43.3279 13.0559C43.3279 12.6226 43.328 12.5001 43.3007 12.0691C43.2962 11.9976 43.2905 11.9261 43.2849 11.8535C43.0988 9.67683 42.0405 7.62034 40.2472 6.34085C38.1034 4.81068 35.4423 4.81409 32.9162 4.86059C32.8629 4.86173 32.8096 4.86286 32.7563 4.864C29.857 4.91731 29.2286 4.85492 26.3305 4.85946L22.4943 2.0464C22.2368 1.85697 21.9249 1.75488 21.605 1.75488H3.41991C1.53471 1.75488 2.40304e-09 3.29753 2.40304e-09 5.19407V11.7571C2.40304e-09 12.9856 -9.27478e-06 14.5679 0.00112502 16.4214H2.2255V8.63667L2.21982 8.64461V5.94498C2.21982 4.73922 3.19531 3.75805 4.39427 3.75805H20.8983C20.9743 3.75805 21.0469 3.78188 21.1082 3.82611L25.1508 6.75601C25.3198 6.87851 25.5229 6.9443 25.7327 6.9443H29.0675C32.5385 6.9443 32.2095 6.93409 35.6805 6.91254C37.2583 6.90233 38.7442 7.61807 39.7492 8.82383C40.3481 9.54298 40.6453 10.4754 40.7951 11.4486C40.9312 12.3368 40.9437 13.2578 40.9482 14.0813C40.9527 14.8617 40.955 15.641 40.9573 16.4202H43.3268C43.3268 16.0856 43.3279 15.751 43.3268 15.4175C43.3279 14.6303 43.3279 13.8431 43.3279 13.0559Z"
                fill="#F37421"
            />
            <path
                d="M40.9574 16.4199C40.9665 19.5109 40.9586 22.5973 40.9586 25.6951C40.9586 25.7371 40.9586 25.779 40.9586 25.8199C40.9586 26.7148 40.6897 27.4056 39.6847 27.4204C36.2603 27.4703 32.8291 27.4079 29.4035 27.4079C26.6857 27.4079 23.9679 27.4079 21.2513 27.4079C18.4961 27.4079 15.7397 27.4079 12.9845 27.4079C10.168 27.4079 7.35042 27.4079 4.53396 27.4079C2.29145 27.4079 2.23134 27.3432 2.2268 25.0894V16.4222H0.00244141C0.00357571 19.0957 0.00470751 22.3376 0.00697611 25.9424C0.00811041 28.5399 1.12426 29.6402 3.74449 29.6436C9.01444 29.6515 9.35133 29.6447 14.6201 29.647C14.6598 29.647 14.6961 29.6493 14.7336 29.6504H15.6172C16.0959 29.6504 16.4883 30.0417 16.4883 30.5215V32.2717H9.17779C6.58478 32.2717 4.46251 34.394 4.46251 36.987H39.4375C39.4375 34.394 37.3152 32.2717 34.7222 32.2717H26.798V30.5215C26.798 30.0429 27.1893 29.6504 27.6691 29.6504C27.6691 29.6504 32.4854 29.6504 32.4876 29.6504C36.5598 29.6413 35.6977 29.6527 39.7698 29.639C41.8433 29.6322 42.964 28.6216 43.2351 26.5617C43.3156 25.9503 43.3247 25.3264 43.3247 24.7071C43.3281 21.9451 43.3293 19.1831 43.3293 16.4222H40.9574V16.4199Z"
                fill="#4D469D"
            />
            <path
                d="M38.2359 25.9454H34.749V25.0946H38.2359C38.3017 25.0946 38.355 25.0413 38.355 24.9755V21.3242H39.2057V24.9755C39.2068 25.5098 38.7713 25.9454 38.2359 25.9454Z"
                fill="white"
            />
        </svg>
    );
}
