import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import Image from "next/image";
export default function MainImage(): ReactElement {
    return (
        <div className={styles["mainBannerWrapper"]}>
            <Image
                src="/medicalsoftwarequotes/assets/images/mainOpt.png"
                className={styles["background-img"]}
                alt="Medical Software"
                layout="intrinsic"
                priority
                width={634}
                height={700}
            />
        </div>
    );
}
